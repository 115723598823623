import React from 'react';
import { Link } from "react-router-dom";
/* MUI */
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

function CrawlingExtracting() {
  return (
    <Stack spacing={2} sx={{mb: 5}}>
      <Typography variant="h1" sx={{py: 1}}>
        Crawling and Extracting Data
      </Typography>
      <Typography variant="p">
        FLD Crawlers can extract data, content, and tag attributes from webpages during a crawl. Extracted data can be used for content analysis, quality assurance and testing purposes, performance optimization, and other data processing tasks.
      </Typography>
      <Typography variant="h3">
        How do I extract data from a website?
      </Typography>
      <Typography variant="p">
        When you create an FLD account you can configure custom extractor rules to find and extract data from web pages. Extractor rules can be configured to find HTML tags based on tag type and matching tag attributes.
      </Typography>
      <Typography component="ul">
        <Typography component="li">Extract data from a specific tag</Typography>
        <Typography component="li">Extract data from a tag with a specific attribute present</Typography>
        <Typography component="li">Extract data from a tag with an attribute matching a specific value</Typography>
      </Typography>
      <Typography variant="p">
        Extractors can be configured to get tag content or attributes.
      </Typography>
      <Typography component="ul">
        <Typography component="li">Extract all text content from within a matching tag</Typography>
        <Typography component="li">Extract all attribute values from a matching tag</Typography>
        <Typography component="li">Extract a specific attribute value from a matching tag</Typography>
      </Typography>
      <Typography variant="h3">
        What can I do with extracted content and data?
      </Typography>
      <Typography variant="p">
        FLD Tools have several options to analyze and process extracted data. Crawl data is saved for future use and crawls can be scheduled for on-going analysis over time.
      </Typography>
      <Typography component="ul">
        <Typography component="li">View and analyze extract data in FLD Tools</Typography>
        <Typography component="li">Download all extracted data in a CSV</Typography>
        <Typography component="li">Send extracted data to third-party APIs in FLD Tools</Typography>
      </Typography>
      
      <Typography variant="h3">
        Which Tools do I use to Extract Data?
      </Typography>
      <Typography variant="p">
        FLD Tools support customized data extraction and export with an account.
      </Typography>
      <List disablePadding>
        <ListItemButton
          component={Link}
          to={`/crawl`}
        >
          <ListItemAvatar>
            <Button
              size="small"
              variant="contained"
              sx={{mr: 2, width: 160}}
            >
              Crawl Now
            </Button>
          </ListItemAvatar>
          <ListItemText
            primary="Crawl"
            secondary="The Crawl tool can be configured and customized as needed for your sites and data sources. Add customized extractors for specific tags, data, and content then export crawl data to CSV or via APIs."
          />
        </ListItemButton>
        <ListItemButton
          component={Link}
          to={`/url-fetch`}
        >
          <ListItemAvatar>
            <Button
              size="small"
              variant="contained"
              sx={{mr: 2, width: 160}}
            >
              Start Now
            </Button>
          </ListItemAvatar>
          <ListItemText
            primary="URL Performance"
            secondary="The URL Performance tool supports customized data extraction and can be used to test URLs before full-scale crawls and diagnose potential data extraction issues."
          />
        </ListItemButton>
      </List>
    </Stack>
  );
}

export default CrawlingExtracting;
