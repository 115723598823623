import React from 'react';
import { Link } from "react-router-dom";
/* MUI */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
/* MUI Icons */
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

function Seo() {
  return (
    <Stack spacing={2} sx={{mb: 5}}>
      <Typography variant="h1" sx={{py: 1}}>
        Tools for Search Engine Optimization
      </Typography>
      <Typography variant="p">
        {`Search engines need to be able to predictably crawl your website and extract meaningful
         content and technical information to accurately rank and return your pages when users
          search for relevant keywords and topics. FLD Tools include helpful content
           research and technical analysis tools to monitor and improve search engine
            optimization factors including keyword optimization, interlinking opportunities,
             URL structure and redirects, page speed performance, and other technical configurations.`}
      </Typography>
      <Typography variant="h3">
        How Can I Optimize My Site?
      </Typography>
      <Typography variant="p">
        Search engine optimization techniques can vary from site to site and depend on technical factors, market interest, content strategies, and other aspects of search engine marketing.
      </Typography>
      <List disablePadding>
        <ListItemButton
          component={Link}
          to={`/tech-tips/seo/basic-seo`}
        >
          <ListItemIcon>
            <ChevronRightIcon />
          </ListItemIcon>
          <ListItemText
            primary="Basic SEO Best Practices"
            secondary={
              <Stack spacing={1}>
                <Box>
                  Check for basic "best practice" items including title, meta data description, and page headings
                </Box>
                <Box>
                  Read more...
                </Box>
              </Stack>
            }
          />
        </ListItemButton>
        <ListItemButton
          component={Link}
          to={`/tech-tips/seo/technical-audit`}
        >
          <ListItemIcon>
            <ChevronRightIcon />
          </ListItemIcon>
          <ListItemText
            primary="Technical - URLs and Redirects"
            secondary={
              <Stack spacing={1}>
                <Box>
                  Analyze and monitor websites for URL, redirect, alternate language tagging, and page speed performance issues and opportunities
                </Box>
                <Box>
                  Read more...
                </Box>
              </Stack>
            }
          />
        </ListItemButton>
      </List>
      <Typography variant="h3">
        Which Tools do I Use for Search Engine Optimization?
      </Typography>
      <Typography variant="p">
        FLD Tools can be used together for common SEO monitoring and benchmarking tasks as well as in-depth research, technical QA, and analysis. Tools include scheduled monitoring and alerting as well as change detection and email updates when websites and pages have changed.
      </Typography>
      <List disablePadding>
        <ListItemButton
          component={Link}
          to={`/change`}
        >
          <ListItemAvatar>
            <Button
              size="small"
              variant="contained"
              sx={{mr: 2, width: 160}}
            >
              Check Now
            </Button>
          </ListItemAvatar>
          <ListItemText
            primary="Change Detector"
            secondary="Change Detector can track content, code, and visual changes to web pages and includes alerting when major parts of your site changes before it impacts organic visibility and rankings"
          />
        </ListItemButton>
        <ListItemButton
          component={Link}
          to={`/url-fetch`}
        >
          <ListItemAvatar>
            <Button
              size="small"
              variant="contained"
              sx={{mr: 2, width: 160}}
            >
              Start Now
            </Button>
          </ListItemAvatar>
          <ListItemText
            primary="URL Performance"
            secondary="The URL Performance tool analyzes page load speed and performance, URL response headers and redirects, on-page code and tags, as well as content and keywords"
          />
        </ListItemButton>
        <ListItemButton
          component={Link}
          to={`/crawl`}
        >
          <ListItemAvatar>
            <Button
              size="small"
              variant="contained"
              sx={{mr: 2, width: 160}}
            >
              Crawl Now
            </Button>
          </ListItemAvatar>
          <ListItemText
            primary="Crawl"
            secondary="The Crawl tool can identify technical issues, extract keywords and content, and verify technical and content configuration"
          />
        </ListItemButton>
        <ListItemButton
          component={Link}
          to={`/tfidf`}
        >
          <ListItemAvatar>
            <Button
              size="small"
              variant="contained"
              sx={{mr: 2, width: 160}}
            >
              Analyze Content
            </Button>
          </ListItemAvatar>
          <ListItemText
            primary="Content Analysis"
            secondary="Content Analysis can analyze content, topic, and keyword usage across a group of pages or website to identify duplicated content, content gaps, interlinking opportunities, and keyword analysis"
          />
        </ListItemButton>
        <ListItemButton
          component={Link}
          to={`/alt-lang`}
        >
          <ListItemAvatar>
            <Button
              size="small"
              variant="contained"
              sx={{mr: 2, width: 160}}
            >
              Check Now
            </Button>
          </ListItemAvatar>
          <ListItemText
            primary="Alternate Language Tagging Check"
            secondary="Verify alternate language tagging and hreflang tags are configured correctly"
          />
        </ListItemButton>
      </List>
    </Stack>
  );
}

export default Seo;
