import React from 'react';
import { Link } from "react-router-dom";
/* MUI */
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

function PerformanceExternal() {
  return (
    <Stack spacing={2} sx={{mb: 5}}>
      <Typography variant="h1" sx={{py: 1}}>
        Optimizing External Resources
      </Typography>
      <Typography variant="p">
        External resources can include CSS and JavaScript files, images, videos, and other files or resources you manage yourself as well as third-party resources including scripts, iframes, and images or video. External resources have to be loaded seperately from your web page source code and can impact performance if they're not optimized for performance.
      </Typography>
      <Typography variant="p">
        Loading external resources typically involves waiting to establish a network connection to a new host (including any redirects and SSL/HTTPS handshaking) and waiting for the full response. Depending on how your site is configured initial rendering may be delayed by slow-loading external resources or the page may need to be re-rendered everytime an external resource gets fully loaded.
      </Typography>
      <Typography variant="h3">
        Which Tools do I Use to Optimize External Resources?
      </Typography>
      <Typography variant="p">
        You'll need to analyze how your external resources are loading and impacting your site performance to get started. Once you identify which external files, media, or resources are impacting your load times, rendering processes, or delaying interaction you can configure your site to defer loading or rendering features that utilize externally loaded images, video, or code.
      </Typography>
      <List disablePadding>
        <ListItemButton
          component={Link}
          to={`/url-fetch`}
        >
          <ListItemAvatar>
            <Button
              size="small"
              variant="contained"
              sx={{mr: 2, width: 160}}
            >
              Start Now
            </Button>
          </ListItemAvatar>
          <ListItemText
            primary="URL Performance"
            secondary="The URL Performance tool can show you how long each step in the page loading process takes. You can also see what external resources are loaded, how large their response is, and how long they take to load."
          />
        </ListItemButton>
      </List>
    </Stack>
  );
}

export default PerformanceExternal;
