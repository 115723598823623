import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { ApiContext, BackendContext, TasksContext, UserContext } from "../../global/context";
import { authUser, checkBackend, fetchUserTasks } from "../../api/user";
import { ThemeProvider } from "@mui/material";
import { createTheme } from '@mui/material/styles';
import './App.css';
/* FLD */
import About from "../About/About";
import Account from "../Account/Account";
import AccountCreate from "../AccountCreate/AccountCreate";
import AccountLogin from "../AccountLogin/AccountLogin";
import AltLang from "../AltLang/AltLang";
import Change from "../Change/Change";
import Crawl from "../Crawl/Crawl";
import Fetch from "../Fetch/Fetch";
import Footer from "../Footer/Footer";
import Home from "../Home/Home";
import Itunes from "../Itunes/Itunes";
import Nav from "../Nav/Nav";
import Schema from "../Schema/Schema";
import TechTips from "../TechTips/TechTips";
import Terms from "../Terms/Terms";
import Tfidf from "../Tfidf/Tfidf";
/* MUI */
import Box from '@mui/material/Box';
/* Google Analytics */
import ReactGA from 'react-ga4';
import urlConfig from '../../global/urlConfig.json';

ReactGA.initialize(process.env.REACT_APP_GA_ID || 'null');

function App() {
  const noUser = {
    loggedIn: false,
    username: '',
    userid: -2,
    preferences: {},
  };

  const unknownBackend = {
    connected: false,
    unavailable: false,
    connecting: false,
  };

  const [user, setUser] = useState(noUser);
  const [tasks, setTasks] = useState([]);
  const [backend, setBackend] = useState(unknownBackend);
  const [pageTitle, setPageTitle] = useState('');
  const [pageDescription, setPageDescription] = useState('');
  const [pagePath, setPagePath] = useState('');
  const location = useLocation();

  useEffect(() => {
    if(user.userid === -2) {
      authUser()
      .then((data) => {
        if(data.status === 'success') {
          localStorage.setItem('jwt_token', data.user.access_token);
          setUser({
            loggedIn: true,
            username: data.user.username,
            userid: data.user.id,
            preferences: data.user.preferences,
          });
          refreshTasks();
        }
        else {
          setUser({
            loggedIn: false,
            username: '',
            userid: -1,
            preferences: {},
          });
          setTasks([]);
          //localStorage.setItem('jwt_token', '');
        }
      });
    }
  }, [user]);

  useEffect(() => {
    if(!backend.connected && !backend.unavailable) {
      setBackend({
        connected: false,
        unavailable: false,
        connecting: true,
      });
      checkBackend()
      .then((data) => {
        if(data.status === 'F*** Data') {
          setBackend({
            connected: true,
            unavailable: false,
            connecting: false,
          });
        }
        else {
          setBackend({
            connected: false,
            unavailable: true,
            connecting: false,
          });
        }
      });
    }
  }, [backend.connected, backend.unavailable]);

  const logout = () => {
    setUser(noUser);
    localStorage.removeItem('jwt_token');
  };

  const refreshTasks = () => {
    fetchUserTasks()
    .then((tasks) => {
      if(tasks.status === 'success') {
        setTasks(tasks.tasks);
      }
    });
  };

  useEffect(() => {
    const thisPage = urlConfig.find((url) => location.pathname.startsWith(url.path));
    setPageTitle(thisPage?.title || 'FourLetterData');
    setPageDescription(thisPage?.description || 'Website');
    setPagePath(thisPage?.path || '/');
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
      title: thisPage?.title || 'No page title'
    });
  }, [location]);

  const theme = createTheme({
    palette: {
      primary: {
        main: '#0084ff',
      },
      secondary: {
        main: '#edf2ff',
      },
      neutral: {
        main: '#efefef',
      },
      dark: {
        main: '#0052cc',
      },
      light: {
        main: '#FFF',
        contrastText: "#0052cc",
      },
      lightBlue: {
        main: '#4ca7ff',
      },
      lightGrey: {
        main: '#999',
      }
    },
    typography: {
      h1: {
        fontSize: '2rem',
        color: '#0052cc',
      },
      h2: {
        fontSize: '1.75rem',
      },
      h3: {
        fontSize: '1.5rem',
      },
      h5: {
        fontSize: '1.25rem',
        color: '#666666',
        fontWeight: 700,
      },
      h6: {
        fontSize: '1rem',
        fontWeight: 600,
        color: '#333333',
      },
      dataLabel: {
        fontWeight: 900,
      }
    }
  });

  return (
    <HelmetProvider>
      <BackendContext.Provider value={[backend, setBackend]}>
        <UserContext.Provider value={[user, setUser]}>
          <ApiContext.Provider value={{ refreshTasks: refreshTasks }}>
            <TasksContext.Provider value={[tasks, setTasks]}>
              <ThemeProvider theme={theme}>
                <Helmet>
                  <title>{pageTitle}</title>
                  <meta name="description" content={pageDescription} />
                  <link rel="canonical" href={`https://www.fourletterdata.com${pagePath}`} />
                  <meta property="og:title" content={pageTitle} />
                  <meta property="og:description" content={pageDescription} />
                  <meta property="og:url" content={`https://www.fourletterdata.com${pagePath}`} />
                  <meta property="og:image" content="https://www.fourletterdata.com/fld-share.png" />
                  <meta property="og:type" content="website" />
                </Helmet>
                <Nav logout={logout} />
                <Box sx={{minHeight: {xs: 'calc(100vh - 6.75rem)', sm: 'calc(100vh - 7.25rem)'}}}>
                  <Routes>
                    <Route path="*" element={<Home/>} />
                    <Route path="/schema-search" element={<Schema/>} />
                    <Route path="/url-fetch">
                      <Route path="" element={<Fetch/>} />
                      <Route path=":taskId" element={<Fetch/>} />
                    </Route>
                    <Route path="/alt-lang">
                      <Route path="" element={<AltLang/>} />
                      <Route path=":taskId" element={<AltLang/>} />
                    </Route>
                    <Route path="/itunes-search" element={<Itunes/>} />
                    <Route path="/tfidf">
                      <Route path="" element={<Tfidf/>} />
                      <Route path=":taskId" element={<Tfidf/>} />
                    </Route>
                    <Route path="/crawl">
                      <Route path="" element={<Crawl/>} />
                      <Route path=":taskId" element={<Crawl/>} />
                    </Route>
                    <Route path="/change">
                      <Route path="" element={<Change/>} />
                      <Route path=":taskId" element={<Change/>} />
                    </Route>
                    <Route path="/terms" element={<Terms/>} />
                    <Route path="/about" element={<About/>} />
                    <Route path="/account">
                      <Route path="" element={<Account logout={logout} />} />
                      <Route path="new" element={<AccountCreate />} />
                      <Route path="login" element={<AccountLogin logout={logout} />} />
                    </Route>
                    <Route path="/tech-tips">
                      <Route path="" element={<TechTips />} />
                      <Route path="*" element={<TechTips />} />
                    </Route>
                  </Routes>
                </Box>
                <Footer />
              </ThemeProvider>
            </TasksContext.Provider>
          </ApiContext.Provider>
        </UserContext.Provider>
      </BackendContext.Provider>
    </HelmetProvider>
  );
}

export default App;
