import React from 'react';
import { Link } from "react-router-dom";
/* FLD */
import SiteSpeedGraph from '../SiteSpeedGraph/SiteSpeedGraph';
/* MUI */
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

function PerformanceNetwork() {
  return (
    <Stack spacing={2} sx={{mb: 5}}>
      <Typography variant="h1" sx={{py: 1}}>
        Network and Server Performance
      </Typography>
      <Typography variant="p">
        {`Network lags, server delays, caching issues, and other backend problems 
          will delay how quickly a browser can start rendering a page, and those same 
          issues can cause additional rendering delays when external resources 
          encounter network or server waits.`}
      </Typography>
      <SiteSpeedGraph data={{
        connectStart: 2000,
        connectEnd: 3000,
        domainLookupStart: 1500,
        domainLookupEnd: 2000,
        redirectStart: 100,
        redirectEnd: 1000,
        requestStart: 3500,
        responseStart: 4000,
        responseEnd: 5000,
        domContentLoadedEventEnd: 5200,
        domComplete: 5500,
        loadEventEnd: 5500,
      }} />
      <Typography variant="p">
        Network issues can include delays from redirects, DNS lookups, SSL connection negotiation, or just slow network performance when transfering the server response. Reducing redirects, cleaning up DNS records, and evaluating SSL configuration can help minimize network-related delays. Other network performance issues may be harder to diagnose and may depending on where end-users are located in relation to your server as well as other transient network issues. 
      </Typography>
      <Typography variant="p">
        Server delays or other backend issues such as poor database performance, caching issues, load balancing problems, and anything else related to actually processing an incoming request cannot be easily diagnosed using external tools. These server issues increse the time it takes for an incoming request to get processed and sent back to the browser. This delay only shows up as TCP-delay in the overall network loading process, if network requests are slow due to long TCP responses then your server infrastructure may be causing performance issues.
      </Typography>
      <Typography variant="h3">
        Which Tools do I Use to Optimize Network and Server Performance?
      </Typography>
      <Typography variant="p">
        Network issues typically affect all pages on a site (assuming they're loading from the same source) and can be affected by different stages of the network request and response process. Server issues may be present site-wide or might impact specific pages or templates if those pages take longer to load or process on your backend.
      </Typography>
      <List disablePadding>
        <ListItemButton
          component={Link}
          to={`/crawl`}
        >
          <ListItemAvatar>
            <Button
              size="small"
              variant="contained"
              sx={{mr: 2, width: 160}}
            >
              Crawl Now
            </Button>
          </ListItemAvatar>
          <ListItemText
            primary="Crawl"
            secondary="The Crawl tool measures basic load time and response sizes and captures redirect information so you can identify which sections of your site could be experiencing network or server performance issues."
          />
        </ListItemButton>
        <ListItemButton
          component={Link}
          to={`/url-fetch`}
        >
          <ListItemAvatar>
            <Button
              size="small"
              variant="contained"
              sx={{mr: 2, width: 160}}
            >
              Start Now
            </Button>
          </ListItemAvatar>
          <ListItemText
            primary="URL Performance"
            secondary="The URL Performance tool includes detailed network performance to identify which stage of the network connection process is affecting initial page rendering and network/server issues with external resources."
          />
        </ListItemButton>
      </List>
    </Stack>
  );
}

export default PerformanceNetwork;
