import React from 'react';
import { Link } from "react-router-dom";
/* MUI */
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
/* MUI Icons */
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
/* FLD Constants */
import techTips from '../../global/techTips.json';

function GettingStarted() {
  const sectionURL = 'tech-tips';
  return (
    <Stack spacing={2} sx={{mb: 5}}>
      <Typography variant="h1" sx={{py: 1}}>
        Getting Started with FourLetterData
      </Typography>
      <Typography variant="p">
        {`FourLetterData tools are built for web developers and digital marketers 
          looking to measure and optimize their websites and front ends.`}
      </Typography>
      <Box>
        <Grid container spacing={3} alignItems="top" justifyContent="space-between">
          <Grid item xs={12} sm={4}>
            <Card variant="outlined" sx={{minHeight: 250}}>
              <CardHeader title={<Typography variant="h3">Web Development</Typography>} />
              <CardContent>
                <Typography component="ul">
                  <Typography component="li">Rendering and Performance</Typography>
                  <Typography component="li">URL Redirects and Server Response</Typography>
                  <Typography component="li">Site Crawling</Typography>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card variant="outlined" sx={{minHeight: 250}}>
              <CardHeader title={<Typography variant="h3">Digital Marketing</Typography>} />
              <CardContent>
                <Typography component="ul">
                  <Typography component="li">Keyword and Content Optimization</Typography>
                  <Typography component="li">Find and Track Changes</Typography>
                  <Typography component="li">Website Crawling and Audits</Typography>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card variant="outlined" sx={{minHeight: 250}}>
              <CardHeader title={<Typography variant="h3">Research</Typography>} />
              <CardContent>
                <Typography component="ul">
                  <Typography component="li">Analyze Keywords and Topics</Typography>
                  <Typography component="li">Tracking Competitor Content and Site Changes</Typography>
                  <Typography component="li">Scrape and Push Data to APIs</Typography>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box>
        {techTips.slice(1).map((section, index) => (
          <Box key={index}>
            <List>
              <ListItem>
                <Typography variant="h2">
                  {section.label}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="p">
                  {section.description}
                </Typography>
              </ListItem>
              <List disablePadding>
                <ListItemButton
                  component={Link}
                  to={`/${sectionURL}${section.path}`}
                >
                  <ListItemIcon>
                    <ChevronRightIcon />
                  </ListItemIcon>
                  <ListItemText primary={`Getting Started with ${section.label}`} />
                </ListItemButton>
                {section.items.map((item, key) => (
                  <ListItemButton
                    key={key}
                    component={Link}
                    to={`/${sectionURL}${item.path}`}
                  >
                    <ListItemIcon>
                      <ChevronRightIcon />
                    </ListItemIcon>
                    <ListItemText primary={item.label} />
                  </ListItemButton>
                ))}
              </List>
            </List>
          </Box>
        ))}
      </Box>
    </Stack>
  );
}

export default GettingStarted;
