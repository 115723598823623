import React from 'react';
import { Link } from "react-router-dom";
/* MUI */
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

function SeoBasic() {
  return (
    <Stack spacing={2} sx={{mb: 5}}>
      <Typography variant="h1" sx={{py: 1}}>
        SEO Basics and Best Practices
      </Typography>
      <Typography variant="p">
        {`Many internet search engines such as Google and Bing utilize
         common page meta data, such as title and meta description,
          as well as on-page content and tags including page headings
           and commonly used keywords. Search engines that can accurately
            crawl your site and find page meta data and content will
             index and return your site pages and results when users search. `}
      </Typography>
      <Typography variant="p">
        {`FLD Tools include analysis and verification features for common SEO best practices.`}
      </Typography>
      <Typography variant="h3">
        Meta Data
      </Typography>
      <Typography variant="p">
        {`Page meta data typically shows up in search result pages so every page should have a unique and descriptive title and description.`}
      </Typography>
      <Typography component="ul">
        <Typography component="li">Title Tags - Analyze keywords, find duplicated or missing tags, and identify titles that are too short or too long</Typography>
        <Typography component="li">Meta Description - Find missing or duplicated descrptions and analyze content length and keywords</Typography>
      </Typography>
      <Typography variant="h3">
        On-Page Content
      </Typography>
      <Typography variant="p">
        {`Page content needs to be accessible and unique so search engines can authoritatively index and rank your site.`}
      </Typography>
      <Typography component="ul">
        <Typography component="li">On-Page Headings and Content - Verify page headings and other content is visible and accessible to search engines</Typography>
        <Typography component="li">Duplicate Content - Find sections and pages on your site that contain duplicate content</Typography>
      </Typography>
      <Typography variant="h3">
        Which Tools Include Basic SEO Features?
      </Typography>
      <List disablePadding>
        <ListItemButton
          component={Link}
          to={`/url-fetch`}
        >
          <ListItemAvatar>
            <Button
              size="small"
              variant="contained"
              sx={{mr: 2, width: 160}}
            >
              Start Now
            </Button>
          </ListItemAvatar>
          <ListItemText
            primary="URL Performance"
            secondary="The URL Performance tool analyzes page load speed and performance, URL response headers and redirects, on-page code and tags, as well as content and keywords"
          />
        </ListItemButton>
        <ListItemButton
          component={Link}
          to={`/crawl`}
        >
          <ListItemAvatar>
            <Button
              size="small"
              variant="contained"
              sx={{mr: 2, width: 160}}
            >
              Crawl Now
            </Button>
          </ListItemAvatar>
          <ListItemText
            primary="Crawl"
            secondary="The Crawl tool can identify technical issues, extract keywords and content, and verify technical and content configuration"
          />
        </ListItemButton>
        <ListItemButton
          component={Link}
          to={`/tfidf`}
        >
          <ListItemAvatar>
            <Button
              size="small"
              variant="contained"
              sx={{mr: 2, width: 160}}
            >
              Analyze Content
            </Button>
          </ListItemAvatar>
          <ListItemText
            primary="Content Analysis"
            secondary="Content Analysis can analyze content, topic, and keyword usage across a group of pages or website to identify duplicated content, content gaps, interlinking opportunities, and keyword analysis"
          />
        </ListItemButton>
      </List>
    </Stack>
  );
}

export default SeoBasic;
