import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
/* MUI Icons */
import ExpandCircleDownRoundedIcon from '@mui/icons-material/ExpandCircleDownRounded';
/* MUI Colors */
import { grey } from '@mui/material/colors';
/* visx */
import { AxisBottom, AxisLeft } from '@visx/axis';
import { BarStackHorizontal } from "@visx/shape";
import { Group } from "@visx/group";
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { LegendOrdinal } from '@visx/legend';

function SiteSpeedGraph(props: {data: any}) {
  const { data } = props;
  const [graphData, setGraphData] = useState([]);
  const [graphKeys, setGraphKeys] = useState([]);
  const [graphColors, setGraphColors] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const baseKeys = ['Redirect', 'Connect Waiting', 'DNS', 'TCP', 'Request Waiting', 'Request', 'Download', 'Initial Render', 'DOM Processing', 'DOM Loading Content', 'Final Render'];
  const baseColors = ["#6666DD", "#DDDDDD", "#0066DD", "#0099FF", "#DDDDDD", "#CCCC66", "#EEEE00", "#33FF33", "#99FF00", "#00FFFF", "#009933"];

  useEffect(() => {
    const graphData = [
      {
        'stage': 'Rendering',
        'start': data.responseEnd,
        'Initial Render': data.domInteractive - data.responseEnd,
        'DOM Processing': data.domContentLoadedEventStart - data.domInteractive,
        'DOM Loading Content': data.domContentLoadedEventEnd - data.domContentLoadedEventStart,
        'Final Render': data.domComplete - data.domContentLoadedEventEnd,
      },
      {
        'stage': 'Request',
        'start': data.connectEnd,
        'Request Waiting': data.requestStart - data.connectEnd,
        'Request': data.responseStart - data.requestStart,
        'Download': data.responseEnd - data.responseStart,
      },
      {
        'stage': 'Connection',
        'start': data.redirectStart,
        'Redirect': data.redirectEnd - data.redirectStart,
        'Connect Waiting': data.domainLookupStart - data.redirectEnd,
        'DNS': data.domainLookupEnd - data.domainLookupStart,
        'TCP': data.connectEnd - data.connectStart,
      }
    ];

    const keys = ['start'];
    const colors = ["transparent"];
    baseKeys.forEach((key, index) => {
      graphData.forEach((item) => {
        if(item[key] && item[key] > 0 && key !== 'stage' && key !== 'start') {
          keys.push(key);
          colors.push(baseColors[index]);
        }
      })
    });

    setGraphData(graphData);
    setGraphKeys(keys);
    setGraphColors(colors);

  }, [data])

  const initialData = [
    {
      'stage': 'Rendering',
      'Waiting': 0,
      'DOM Processing': 0,
      'DOM Loading Content': 0,
      'Final Render': 0,
    },
    {
      'stage': 'Request',
      'Waiting': 0,
      'Request': 0,
      'Download': 0,
    },
    {
      'stage': 'Connection',
      'Waiting': 0,
      'Redirect': 0,
      'DNS': 0,
      'TCP': 0,
    }
  ];

  const getStage = (d) => d.stage;

  const timeScale = scaleLinear({
    domain: [0, data.loadEventEnd ?
      data.loadEventEnd : data.responseEnd ?
      data.responseEnd :
      1000
    ],
    nice: true,
  });

  const stageScale = scaleBand({
    domain: initialData.map(getStage),
    padding: 0,
  });

  const colorScale = scaleOrdinal({
    domain: graphKeys,
    range: graphColors,
  });

  const legendScale = scaleOrdinal({
    domain: graphKeys.slice(1),
    range: graphColors.slice(1),
  });

  const height = 200;
  const width = 650;

  timeScale.rangeRound([0, width - 130]);
  stageScale.rangeRound([150, 0]);

  let stageStart = 0
  let stageEnd = 0

  return (
    <Stack>
      <Box>
        <LegendOrdinal
          scale={legendScale}
          labelMargin="0 5px 0 0"
          style={{
            display: "flex",
            fontSize: 12
          }}
          direction="row"
        />
        <svg width={width} height={height}>
          <Group top={10} left={100}>
            <BarStackHorizontal
              data={graphData}
              keys={graphKeys}
              height={250}
              y={getStage}
              xScale={timeScale}
              yScale={stageScale}
              color={colorScale}
            >
              {(barStacks) =>
                barStacks.map((barStack) =>
                  barStack.bars.map((bar) => (
                    <rect
                      key={`barstack-horizontal-${barStack.index}-${bar.index}`}
                      x={bar.x}
                      y={bar.y}
                      width={bar.width}
                      height={bar.height}
                      fill={bar.color}
                    />
                  )),
                )
              }
            </BarStackHorizontal>
            <AxisLeft
              hideAxisLine
              hideTicks
              scale={stageScale}
              stroke={"#00FF00"}
              tickStroke={"#00FF00"}
              tickLabelProps={() => ({
                fill: "#000000",
                fontSize: 11,
                textAnchor: 'end',
                dy: '0.33em',
              })}
            />
            <AxisBottom
              top={150}
              scale={timeScale}
              stroke={"#666666"}
              tickStroke={"#999999"}
              tickLabelProps={() => ({
                fill: "#000000",
                fontSize: 11,
                textAnchor: 'middle',
              })}
            />
          </Group>
        </svg>
      </Box>
      <Stack>
        <Stack direction="row" alignItems="center">
          <IconButton
            onClick={() => setShowDetails(!showDetails)}
            sx={{width: 40, height: 40}}
          >
            <ExpandCircleDownRoundedIcon
              color={showDetails ? "primary" : ""}
              sx={{transform: showDetails ? '': 'rotate(-90deg)', transition: '100ms'}}
              fontSize="small"
            />
          </IconButton>
          <Typography sx={{fontSize: 14}}>Show Details</Typography>
        </Stack>
        {showDetails && (
          <Box sx={{pl: 5}}>
            <TableContainer component={Paper} sx={{maxHeight: 210}} variant="outlined">
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{p: 0, width: 10}} />
                    <TableCell sx={{p: 0, pl: 0.5, width: 75, fontWeight: 600}}>
                      Stage
                    </TableCell>
                    <TableCell sx={{p: 0, width: 30, fontWeight: 600}} align="right">
                      Start
                    </TableCell>
                    <TableCell sx={{p: 0, width: 30, fontWeight: 600}} align="right">
                      End
                    </TableCell>
                    <TableCell sx={{p: 0, pr: 0.5, width: 30, fontWeight: 600}} align="right">
                      Duration (ms)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {[...graphData].reverse().map((stage, index) => (
                    <React.Fragment key={index}>
                       <TableRow>
                        <TableCell sx={{height: 1.25, p: 0, backgroundColor: grey[500]}} colSpan={5} />
                      </TableRow>
                       {Object.keys(stage).map((data, key) => {
                        if (data !== 'stage' && data !== 'start' && stage[data] > 0) {
                          stageStart = stageEnd;
                          stageEnd = stageStart + stage[data];
                          return (
                            <TableRow key={key}>
                              <TableCell sx={{p: 0, backgroundColor: baseColors[baseKeys.indexOf(data)]}} />
                              <TableCell sx={{p:0, pl: 0.5}}>
                                {data}
                              </TableCell>
                              <TableCell sx={{p: 0}} align="right">
                                {stageStart.toFixed(1)}
                              </TableCell>
                              <TableCell sx={{p: 0}} align="right">
                                {stageEnd.toFixed(1)}
                              </TableCell>
                              <TableCell sx={{p: 0, pr: 0.5}} align="right">
                                {stage[data].toFixed(1)}
                              </TableCell>
                            </TableRow>
                          );
                        }
                        return undefined;
                      })}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Stack>
    </Stack>
  );
}

export default SiteSpeedGraph;
