import React from 'react';
import { Link } from "react-router-dom";
/* MUI */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
/* MUI Icons */
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

function Performance() {
  return (
    <Stack spacing={2} sx={{mb: 5}}>
      <Typography variant="h1" sx={{py: 1}}>
        Getting Started with Website Performance
      </Typography>
      <Typography variant="p">
        FLD Tools can be used for large-scale performance monitoring as well as in-depth analysis for page-specific performance optimization.
      </Typography>
      <Typography variant="h3">
        Why does Website Performance Matter?
      </Typography>
      <Typography variant="p">
        Poorly performing sites take too long to load, have an inconsistent user experience, and may be harder for automated processes such as search engines or ML algorithms to parse.
      </Typography>
      <Typography component="ul">
        <Typography component="li">Users waiting for a page to load are more likely to leave your site</Typography>
        <Typography component="li">Slow loading sites are more noticeable on mobile devices</Typography>
        <Typography component="li">Unoptimized assets can increase bandwidth, storage, and processing costs</Typography>
      </Typography>
      <Typography variant="h3">
        What Impacts Website Performance?
      </Typography>
      <Typography variant="p">
        Load times and performance can be impacted by network issues, device limitations, and incorrect design or development choices. Performance issues may not impact every visitor or section of the site so deeper analysis into causes can identify the source of performance issues.
      </Typography>
      <List disablePadding>
        <ListItemButton
          component={Link}
          to={`/tech-tips/performance/server`}
        >
          <ListItemIcon>
            <ChevronRightIcon />
          </ListItemIcon>
          <ListItemText
            primary="Network and Server"
            secondary={
              <Stack spacing={1}>
                <Box>
                  Network issues as well as backend server problems can delay initial page renders or impact how other page assets are requested and loaded. Quickly responding to network requests and speeding up backend response times can reduce time to first render and interaction.
                </Box>
                <Box>
                  Read more...
                </Box>
              </Stack>
            }
          />
        </ListItemButton>
        <ListItemButton
          component={Link}
          to={`/tech-tips/performance/server`}
        >
          <ListItemIcon>
            <ChevronRightIcon />
          </ListItemIcon>
          <ListItemText
            primary="Rendering a Page"
            secondary={
              <Stack spacing={1}>
                <Box>
                  Initial page rendering can get delayed due to technical issues, network delays, and incorrectly designed front end interfaces. Fully rendering a page can also get delayed by slow-loading assets, third-party resources and scripts, as well as dynamic features updating the page layout.
                </Box>
                <Box>
                  Read more...
                </Box>
              </Stack>
            }
          />
        </ListItemButton>
        <ListItemButton
          component={Link}
          to={`/tech-tips/performance/server`}
        >
          <ListItemIcon>
            <ChevronRightIcon />
          </ListItemIcon>
          <ListItemText
            primary="External Resources"
            secondary={
              <Stack spacing={1}>
                <Box>
                  External resources can impact page speeds and delay rendering if they're loaded incorrectly or block other page elements and scripts from loading. Poorly optimized assets or elements loading prematurely can delay page rendering and interactivity.
                </Box>
                <Box>
                  Read more...
                </Box>
              </Stack>
            }
          />
        </ListItemButton>
      </List>
      <Typography variant="h3">
        Which Tools do I Use to Improve Performance?
      </Typography>
      <Typography variant="p">
        Load times and performance can be impacted by network issues, device limitations, and incorrect design or development choices. Performance issues may not impact every visitor or section of the site so deeper analysis into causes can identify the source of performance issues.
      </Typography>
      <List disablePadding>
        <ListItemButton
          component={Link}
          to={`/url-fetch`}
        >
          <ListItemAvatar>
            <Button
              size="small"
              variant="contained"
              sx={{mr: 2, width: 160}}
            >
              Start Now
            </Button>
          </ListItemAvatar>
          <ListItemText
            primary="URL Performance"
            secondary="The URL Performance tool measures how quickly a browser can fetch and render your website. You can identify what's slowing down your website and identify code changes, external resources, and other fixes to improve performance."
          />
        </ListItemButton>
        <ListItemButton
          component={Link}
          to={`/crawl`}
        >
          <ListItemAvatar>
            <Button
              size="small"
              variant="contained"
              sx={{mr: 2, width: 160}}
            >
              Crawl Now
            </Button>
          </ListItemAvatar>
          <ListItemText
            primary="Crawl"
            secondary="The Crawl tool captures basic load times and file sizes during a crawl so you can identify sections of your site, page templates, redirects, or other technical issues that are impacting load times."
          />
        </ListItemButton>
      </List>
    </Stack>
  );
}

export default Performance;
