import React, { useState } from 'react';
import { Link, Route, Routes, useLocation } from "react-router-dom";
/* FLD */
import Change from './Change';
import ChangeTypes from './ChangeTypes';
import ChangeAlerts from './ChangeAlerts';
import Crawling from './Crawling';
import CrawlingExtracting from './CrawlingExtracting';
import CrawlingRendering from './CrawlingRendering';
import CrawlingLimitations from './CrawlingLimitations';
import GettingStarted from './GettingStarted';
import Performance from './Performance';
import PerformanceExternal from './PerformanceExternal';
import PerformanceNetwork from './PerformanceNetwork';
import PerformanceRendering from './PerformanceRendering';
import Seo from './Seo';
import SeoBasic from './SeoBasic';
import SeoTechnical from './SeoTechnical';
/* MUI */
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
/* MUI Icons */
import ExpandCircleDownRoundedIcon from '@mui/icons-material/ExpandCircleDownRounded';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
/* FLD Constants */
import techTips from '../../global/techTips.json';

function TechTips() {
  const sectionURL = 'tech-tips';
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();
  console.log(location.pathname);

  return (
    <Container maxWidth="lg" sx={{mb: 6}}>
      <Stack direction="row" alignItems="center" sx={{pt: 1}}>
      </Stack>
     <Grid container columnSpacing={2}>
      <Grid item xs={12} sm={3}>
        <Stack direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
          sx={(theme) => ({
            mx: .5,
            p: 1.5,
            color: 'white',
            backgroundColor: theme.palette.primary.main,
            transform: "skewx(-7.5deg)",
          })}
        >
          <Box sx={{display: {xs: 'flex', sm: 'none'}, transform: "skewx(7.5deg)" }}>
            <IconButton
              onClick={() => setShowMenu(!showMenu)}
              sx={{
                width: 40,
                height: 40
              }}
            >
              <ExpandCircleDownRoundedIcon
                color={showMenu ? "light" : ""}
                sx={{transform: showMenu ? '': 'rotate(-90deg)', transition: '100ms'}}
              />
            </IconButton>
          </Box>
          <Stack direction="row" spacing={2} alignItems="center">
            <Avatar sx={(theme) => ({
              height: 20,
              width: 20,
              backgroundColor: theme.palette.primary.main,
              transform: "skewx(7.5deg)",
            })}>
              <TipsAndUpdatesIcon fontSize="small" />
            </Avatar>
            <Typography variant="h3">
              Tech Tips
            </Typography>
          </Stack>
          <Box sx={{width: 40}} />
        </Stack>
        <List sx={{display: {xs: showMenu ? 'block' : 'none', sm: 'block'}}}>
          {techTips.map((section, index) => (
            <Box key={index}>
              {section.path !== '' ? (
                <ListItemButton
                  component={Link}
                  selected={location.pathname === `/${sectionURL}${section.path}`}
                  to={`.${section.path}`}
                >
                  {section.label}
                </ListItemButton>
              ) : (
                <ListItem>
                  {section.label}
                </ListItem>
              )}
              <List disablePadding>
                {section.items.map((item, key) => (
                  <ListItemButton
                    key={key}
                    component={Link}
                    selected={location.pathname === `/${sectionURL}${item.path}`}
                    to={`.${item.path}`}
                  >
                    <Box sx={{ml: 2}}>
                      {item.label}
                    </Box>
                  </ListItemButton>
                ))}
              </List>
              <Divider />
            </Box>
          ))}
        </List>
      </Grid>
      <Grid item xs={12} sm={9}>
        <Routes>
          <Route path="/getting-started" element={<GettingStarted />} />
          <Route path="/performance" element={<Performance />} />
          <Route path="/performance/network-server" element={<PerformanceNetwork />} />
          <Route path="/performance/rendering" element={<PerformanceRendering />} />
          <Route path="/performance/external-resources" element={<PerformanceExternal />} />
          <Route path="/crawling" element={<Crawling />} />
          <Route path="/crawling/extracting-data" element={<CrawlingExtracting />} />
          <Route path="/crawling/dynamic-content" element={<CrawlingRendering />} />
          <Route path="/crawling/limitations" element={<CrawlingLimitations />} />
          <Route path="/change-detection" element={<Change />} />
          <Route path="/change-detection/code-content-rendering" element={<ChangeTypes />} />
          <Route path="/change-detection/alerts-updates" element={<ChangeAlerts />} />
          <Route path="/seo" element={<Seo />} />
          <Route path="/seo/basic-seo" element={<SeoBasic />} />
          <Route path="/seo/technical-audit" element={<SeoTechnical />} />
          <Route path="*" element={<GettingStarted />} />
        </Routes>
        <Alert
          severity="info"
          variant="outlined"
          sx={{mt: 10}}
        >
          You may need to <Link to="/account">create an account</Link> to use all FLD tools and features
        </Alert>
      </Grid>
     </Grid>
    </Container>
  );
}

export default TechTips;
