import React from 'react';
/* MUI */
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

function Crawling() {
  return (
    <Stack spacing={2} sx={{mb: 5}}>
      <Typography variant="h1" sx={{py: 1}}>
        Crawling Limitations
      </Typography>
      <Typography variant="p">
        Unfortunately not every website can be crawled using the FLD crawling tools. Here are some technical roadblocks that might prevent a crawl from starting or succeeding.
      </Typography>
      <Typography component="ul">
        <Typography component="li">DDoS protection services such as Cloudflare may require human verification such as CAPTCHAs before allowing access to a site</Typography>
        <Typography component="li">Some sites utilize anti-scraping techniques that only allow access to requests that don't appear to be automated</Typography>
        <Typography component="li">Some sites may block requests originating from certain countries or IP ranges</Typography>
        <Typography component="li">Throttling systems might only allow a certain number of requests within a given time period</Typography>
      </Typography>
      <Typography variant="p">
        If crawls are failing or not starting confirm that the site you're trying to crawl can be reached without any additional authorization or access. Try accessing the site using the URL Performance tool and examine the response headers for any obvious issues or indications or errors. Utilizing a custom user agent or device configuration may alleviate some crawling issues.
      </Typography>
    </Stack>
  );
}

export default Crawling;
