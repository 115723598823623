import React from 'react';
import { Link } from "react-router-dom";
/* MUI */
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

function ChangeAlerts() {
  return (
    <Stack spacing={2} sx={{mb: 5}}>
      <Typography variant="h1" sx={{py: 1}}>
        Getting Updates When Things Change
      </Typography>
      <Typography variant="p">
        FLD Tools include different alerting options depending on what has changed on a website or page and how much. You can set different alerting thresholds so you're only updated when something critical changes.
      </Typography>
      <Typography variant="h3">
        Which Tools Have Change Alerts and Updates?
      </Typography>
      <Typography variant="p">
        FLD Tools have different alerting options to send emails when changes happen, a scheduled task gets completed with no changes, or when a schedule task fails because a website or page was unreachable.
      </Typography>
      <List disablePadding>
        <ListItemButton
          component={Link}
          to={`/change`}
        >
          <ListItemAvatar>
            <Button
              size="small"
              variant="contained"
              sx={{mr: 2, width: 160}}
            >
              Check Now
            </Button>
          </ListItemAvatar>
          <ListItemText
            primary="Change Detector"
            secondary="Get alerts when page code, content, or visual appearance changes."
          />
        </ListItemButton>
        <ListItemButton
          component={Link}
          to={`/url-fetch`}
        >
          <ListItemAvatar>
            <Button
              size="small"
              variant="contained"
              sx={{mr: 2, width: 160}}
            >
              Start Now
            </Button>
          </ListItemAvatar>
          <ListItemText
            primary="URL Performance"
            secondary="Get alerts when response and rendering times change and when critical tags, code, and on-page content gets updated."
          />
        </ListItemButton>
        <ListItemButton
          component={Link}
          to={`/crawl`}
        >
          <ListItemAvatar>
            <Button
              size="small"
              variant="contained"
              sx={{mr: 2, width: 160}}
            >
              Crawl Now
            </Button>
          </ListItemAvatar>
          <ListItemText
            primary="Crawl"
            secondary="Get alerts when URLs, redirects, and status code changes as well as updates to page titles, meta descriptions, canonical tags, and more."
          />
        </ListItemButton>
        <ListItemButton
          component={Link}
          to={`/tfidf`}
        >
          <ListItemAvatar>
            <Button
              size="small"
              variant="contained"
              sx={{mr: 2, width: 160}}
            >
              Analyze Content
            </Button>
          </ListItemAvatar>
          <ListItemText
            primary="Content Analysis"
            secondary="Get alerts when keyword usage changes across a website or group of pages."
          />
        </ListItemButton>
      </List>
    </Stack>
  );
}

export default ChangeAlerts;
