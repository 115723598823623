import React from 'react';
import { Link } from "react-router-dom";
/* MUI */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
/* MUI Icons */
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

function Change() {
  return (
    <Stack spacing={2} sx={{mb: 5}}>
      <Typography variant="h1" sx={{py: 1}}>
        Getting Started with Change Detection
      </Typography>
      <Typography variant="p">
        FLD Tools include change detection features for comparing website, code, and content changes over time including scheduling and automated alerts. Change Detection helps catch errors before they turn into major problems and watch for implementation and site updates automatically.
      </Typography>
      <Typography variant="h3">
        Why Track Website Changes?
      </Typography>
      <Typography variant="p">
        Websites, pages, and segments of a page can change often - sometimes unintentionally. Stay up to date on implementation updates, competitor changes, bug fixes, and more without checking manually or finding out when things go wrong.
      </Typography>
      <Typography component="ul">
        <Typography component="li">Track changes and updates during website updates, technology migrations, and updates</Typography>
        <Typography component="li">Monitor URL changes, redirects, meta tag changes, and other technical SEO configurations</Typography>
        <Typography component="li">Watch for competitor keyword, technology, and website updates</Typography>
        <Typography component="li">Compare site, content, and visual changes over time</Typography>
      </Typography>
      <Typography variant="h3">
        How Can I Check for Changes?
      </Typography>
      <Typography variant="p">
        FLD Tools include several options to track changes to individual web pages and web sites on a daily, weekly, or monthly basis with reports and alerts.
      </Typography>
      <List disablePadding>
        <ListItemButton
          component={Link}
          to={`/tech-tips/change-detection/extracting-data`}
        >
          <ListItemIcon>
            <ChevronRightIcon />
          </ListItemIcon>
          <ListItemText
            primary="Code, Content, and Visual Changes"
            secondary={
              <Stack spacing={1}>
                <Box>
                  Watch for changes on different parts of your website to catch major and minor updates to site code, content, and underlying technology.
                </Box>
                <Box>
                  Read more...
                </Box>
              </Stack>
            }
          />
        </ListItemButton>
        <ListItemButton
          component={Link}
          to={`/tech-tips/crawling/dynamic-content`}
        >
          <ListItemIcon>
            <ChevronRightIcon />
          </ListItemIcon>
          <ListItemText
            primary="Alerts and Updates"
            secondary={
              <Stack spacing={1}>
                <Box>
                  Get actionable insights when sites and web pages change - with customizable alerting to automatically stay up to date.
                </Box>
                <Box>
                  Read more...
                </Box>
              </Stack>
            }
          />
        </ListItemButton>
      </List>
      <Typography variant="h3">
        Which Tools do I Use to Check for Changes?
      </Typography>
      <Typography variant="p">
        FLD Tools can be configured to automatically check for changes and update on a daily, weekly, or monthly status. You can watch for major and minor changes to websites and check for specific code or visual changes and get alerts when things are working correctly or when unexpected changes happen.
      </Typography>
      <List disablePadding>
        <ListItemButton
          component={Link}
          to={`/change`}
        >
          <ListItemAvatar>
            <Button
              size="small"
              variant="contained"
              sx={{mr: 2, width: 160}}
            >
              Check Now
            </Button>
          </ListItemAvatar>
          <ListItemText
            primary="Change Detector"
            secondary="Change Detector can track content, code, and visual changes to web pages and show differences over time."
          />
        </ListItemButton>
        <ListItemButton
          component={Link}
          to={`/url-fetch`}
        >
          <ListItemAvatar>
            <Button
              size="small"
              variant="contained"
              sx={{mr: 2, width: 160}}
            >
              Start Now
            </Button>
          </ListItemAvatar>
          <ListItemText
            primary="URL Performance"
            secondary="The URL Performance tool can track changes to URL redirects, headers, meta tags, and on-page code and content elements with alerts and visual change comparison."
          />
        </ListItemButton>
        <ListItemButton
          component={Link}
          to={`/crawl`}
        >
          <ListItemAvatar>
            <Button
              size="small"
              variant="contained"
              sx={{mr: 2, width: 160}}
            >
              Crawl Now
            </Button>
          </ListItemAvatar>
          <ListItemText
            primary="Crawl"
            secondary="The Crawl tool can track large scale site changes including URL and redirect updates as well as on-page code and content changes with alerting."
          />
        </ListItemButton>
        <ListItemButton
          component={Link}
          to={`/tfidf`}
        >
          <ListItemAvatar>
            <Button
              size="small"
              variant="contained"
              sx={{mr: 2, width: 160}}
            >
              Analyze Content
            </Button>
          </ListItemAvatar>
          <ListItemText
            primary="Content Analysis"
            secondary="Content Analysis can compare keyword and topical changes to a group of pages over time."
          />
        </ListItemButton>
      </List>
    </Stack>
  );
}

export default Change;
