import React from 'react';
import { Link } from "react-router-dom";
/* MUI */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
/* MUI Icons */
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

function Crawling() {
  return (
    <Stack spacing={2} sx={{mb: 5}}>
      <Typography variant="h1" sx={{py: 1}}>
        Getting Started with Crawling
      </Typography>
      <Typography variant="p">
        FLD Tools can be used for flexible large-scale site crawling and include specialized tools and customization options for your specific crawling needs.
      </Typography>
      <Typography variant="h3">
        When should I crawl a website?
      </Typography>
      <Typography variant="p">
        Crawling can be done as needed for audits or data extraction as well as scheduled crawls for QA and testing, change detections, and data monitoring/extract workflows.
      </Typography>
      <Typography component="ul">
        <Typography component="li">Track changes and updates during website updates, technology migrations, and updates</Typography>
        <Typography component="li">Analyze site-wide keywords, topics, and content to identify content gaps, expand interlinking, and improve user experience</Typography>
        <Typography component="li">Watching for major changes (both intended and unintended) across a site or on specific sections and pages</Typography>
        <Typography component="li">Extract content and data from specific tags and elements across a site to download or send to an API</Typography>
      </Typography>
      <Typography variant="h3">
        How can I configure a crawler?
      </Typography>
      <Typography variant="p">
        The FLD Crawlers have some customization options depending on the type of site and content you need to crawl.
      </Typography>
      <List disablePadding>
        <ListItemButton
          component={Link}
          to={`/tech-tips/crawling/extracting-data`}
        >
          <ListItemIcon>
            <ChevronRightIcon />
          </ListItemIcon>
          <ListItemText
            primary="Extracting Data"
            secondary={
              <Stack spacing={1}>
                <Box>
                  FLD Crawlers are preconfigured to extract basic page data and can be customized to extract additional website data.
                </Box>
                <Box>
                  Read more...
                </Box>
              </Stack>
            }
          />
        </ListItemButton>
        <ListItemButton
          component={Link}
          to={`/tech-tips/crawling/dynamic-content`}
        >
          <ListItemIcon>
            <ChevronRightIcon />
          </ListItemIcon>
          <ListItemText
            primary="Dynamic and Rendered Content"
            secondary={
              <Stack spacing={1}>
                <Box>
                  Sites with dynamic features and interactive front ends may need additional processing to fully crawl. Learn how to crawl dynamic websites and discover and extract deeper content.
                </Box>
                <Box>
                  Read more...
                </Box>
              </Stack>
            }
          />
        </ListItemButton>
        <ListItemButton
          component={Link}
          to={`/tech-tips/crawling/limitations`}
        >
          <ListItemIcon>
            <ChevronRightIcon />
          </ListItemIcon>
          <ListItemText
            primary="Limitations"
            secondary={
              <Stack spacing={1}>
                <Box>
                  FLD Crawlers may not be able to crawl or extract data from every site. Learn more about technical issues that may prevent your crawl from succeeding.
                </Box>
                <Box>
                  Read more...
                </Box>
              </Stack>
            }
          />
        </ListItemButton>
      </List>
      <Typography variant="h3">
        Which Tools do I Use to Crawl Websites?
      </Typography>
      <Typography variant="p">
        FLD Tools include several flexible crawler options depending on your use case. All crawlers support open-ended crawls, list crawls, and crawling XML sitemaps. Crawler user agents and device types can be specified as needed and some crawlers can extract custom fields.
      </Typography>
      <List disablePadding>
        <ListItemButton
          component={Link}
          to={`/crawl`}
        >
          <ListItemAvatar>
            <Button
              size="small"
              variant="contained"
              sx={{mr: 2, width: 160}}
            >
              Crawl Now
            </Button>
          </ListItemAvatar>
          <ListItemText
            primary="Crawl"
            secondary="The Crawl tool can be configured and customized as needed for your sites and data sources. Add customized extractors for specific tags, data, and content then export crawl data to CSV or via APIs."
          />
        </ListItemButton>
        <ListItemButton
          component={Link}
          to={`/tfidf`}
        >
          <ListItemAvatar>
            <Button
              size="small"
              variant="contained"
              sx={{mr: 2, width: 160}}
            >
              Analyze Content
            </Button>
          </ListItemAvatar>
          <ListItemText
            primary="Content Analysis"
            secondary="Content analysis will crawl target pages, extract keywords, and calculate term frequency and interdocument frequency scores."
          />
        </ListItemButton>
        <ListItemButton
          component={Link}
          to={`/crawl`}
        >
          <ListItemAvatar>
            <Button
              size="small"
              variant="contained"
              sx={{mr: 2, width: 160}}
            >
              Crawl Now
            </Button>
          </ListItemAvatar>
          <ListItemText
            primary="Alternate Language Tagging"
            secondary="Analyze alternate language tagging on your international pages to find errors and ensure that language-specific and country-specific URLs and tagging is correct on all pages."
          />
        </ListItemButton>
        <ListItemButton
          component={Link}
          to={`/url-fetch`}
        >
          <ListItemAvatar>
            <Button
              size="small"
              variant="contained"
              sx={{mr: 2, width: 160}}
            >
              Start Now
            </Button>
          </ListItemAvatar>
          <ListItemText
            primary="URL Performance"
            secondary="The URL Performance tool can be used to test URLs before full-scale crawls and diagnose potential crawling issues."
          />
        </ListItemButton>
      </List>
    </Stack>
  );
}

export default Crawling;
