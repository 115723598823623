import React from 'react';
import { Link } from "react-router-dom";
/* MUI */
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

function CrawlingRendering() {
  return (
    <Stack spacing={2} sx={{mb: 5}}>
      <Typography variant="h1" sx={{py: 1}}>
        Crawling Dynamic Content and Rendering Pages
      </Typography>
      <Typography variant="p">
        Some sites or webpages may include dynamic features, content, or code that isn't included in the initial page source code. Typically these dynamic features need to be processed and rendered in a browser process to correctly emulate any JavaScript functions, code, or styles that might load or modify the visibily of content on a page.
      </Typography>
      <Typography variant="h3">
        How do I crawl dynamic pages?
      </Typography>
      <Typography variant="p">
        FLD Tools do not support page rendering without creating an account. If a site utilizes dynamic pages and rendering you might still be able to crawl the site from a list of URLs or using the XML sitemap (if available).
      </Typography>
      <Typography variant="p">
        FLD Tools and Crawlers include customized rendering options for screen size, device type, and custom user agents and headers to replicate real-world users and devices.
      </Typography>
      <Typography variant="h3">
        Which Tools will Render Dynamic Content?
      </Typography>
      <Typography variant="p">
        FLD Tools support page rendering, including device optimization, scheduling, and visual change detection with an account.
      </Typography>
      <List disablePadding>
        <ListItemButton
          component={Link}
          to={`/url-fetch`}
        >
          <ListItemAvatar>
            <Button
              size="small"
              variant="contained"
              sx={{mr: 2, width: 160}}
            >
              Start Now
            </Button>
          </ListItemAvatar>
          <ListItemText
            primary="URL Performance"
            secondary="The URL Performance tool includes full rendering support and page load diagnostics to analyze and optimize page rendering."
          />
        </ListItemButton>
        <ListItemButton
          component={Link}
          to={`/crawl`}
        >
          <ListItemAvatar>
            <Button
              size="small"
              variant="contained"
              sx={{mr: 2, width: 160}}
            >
              Check Now
            </Button>
          </ListItemAvatar>
          <ListItemText
            primary="Change Detection"
            secondary="The Change Detection tool tracks code, content, and visual changes to pages over time and includes alerting for major changes."
          />
        </ListItemButton>
      </List>
    </Stack>
  );
}

export default CrawlingRendering;
