import React from 'react';
import { Link } from "react-router-dom";
/* MUI */
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

function SeoTechnical() {
  return (
    <Stack spacing={2} sx={{mb: 5}}>
      <Typography variant="h1" sx={{py: 1}}>
        Technical Best Practices
      </Typography>
      <Typography variant="p">
        {`Search engines use a page's URL as a unique identifier and will often recrawl URLs 
        once they've been discovered to check for technical changes, content updates, 
        and other factors that could affect how that page is indexed and returned to users. 
        Minor technical changes or inconsistencies may cause your website or pages to get 
        indexed improperly resulting in duplicated content and results or de-indexed pages and websites.`}
      </Typography>
      <Typography variant="p">
        {`FLD Tools can verify technical server, URL, and on-page features are configured correctly 
          so your site content and pages get indexed accurately. Many tools include scheduled monitoring 
          so you can discover technical issues before they affect your site's organic visibility.`}
      </Typography>
      <Typography variant="h3">
        Page Indexing
      </Typography>
      <Typography variant="p">
        {`Search engines can only index pages and content that are crawl and include unique content that search engines can render and process.`}
      </Typography>
      <Typography component="ul">
        <Typography component="li">HTTP Response Codes - Analyze server response codes and performance to identify URLs that may not be accessible or indexable</Typography>
        <Typography component="li">Redirects - Analyze and verify URL redirects are configured and working as expected. Improperly resolved or redirected URLs may block search engines from accessing content.</Typography>
        <Typography component="li">Canonical Tags - Ensure canonical tags are configured correctly and can be accessed and crawled by search engines. Incorrect canonical tagging and related URL issues may prevent pages from getting indexed or result in duplicated results.</Typography>
        <Typography component="li">Robots - Check robots declarations in page meta tags, HTTP response headers, and site-wide robots.txt files to verify URLs and pages are correctly configured for access or denial by specific user agents.</Typography>
      </Typography>
      <Typography variant="h3">
        International Tagging
      </Typography>
      <Typography variant="p">
        {`Search engines may have trouble indexing pages and content that contain translated or localized content that's intended for a specific country or language. Utilizing hreflang tags can tell search engines which country and language a page is meant for and what other pages provide similar content for a different country or language.`}
      </Typography>
      <Typography component="ul">
        <Typography component="li">Hreflang Tags - Verify alternate URLs and hreflang language/country code is set correctly</Typography>
        <Typography component="li">Alternate Pages - Verify alternate pages correctly identify all alternate pages consistently</Typography>
        <Typography component="li">HTTP Status and Redirects - Verify alternate URLs have the correct HTTP status and are fully accessible</Typography>
        <Typography component="li">Canonical Tags and Robots - Verify that canonical tags and robots declarations are correctly configured to allow all alternate URLs to get accessed and indexed</Typography>
      </Typography>
      <Typography variant="h3">
        Which Tools Can I Use for Technical SEO?
      </Typography>
      <List disablePadding>
        <ListItemButton
          component={Link}
          to={`/url-fetch`}
        >
          <ListItemAvatar>
            <Button
              size="small"
              variant="contained"
              sx={{mr: 2, width: 160}}
            >
              Start Now
            </Button>
          </ListItemAvatar>
          <ListItemText
            primary="URL Performance"
            secondary="The URL Performance tool analyzes page load speed and performance, URL response headers and redirects, on-page code and tags, as well as content and keywords"
          />
        </ListItemButton>
        <ListItemButton
          component={Link}
          to={`/crawl`}
        >
          <ListItemAvatar>
            <Button
              size="small"
              variant="contained"
              sx={{mr: 2, width: 160}}
            >
              Crawl Now
            </Button>
          </ListItemAvatar>
          <ListItemText
            primary="Crawl"
            secondary="The Crawl tool can identify technical issues, extract keywords and content, and verify technical and content configuration"
          />
        </ListItemButton>
        <ListItemButton
          component={Link}
          to={`/alt-lang`}
        >
          <ListItemAvatar>
            <Button
              size="small"
              variant="contained"
              sx={{mr: 2, width: 160}}
            >
              Check Now
            </Button>
          </ListItemAvatar>
          <ListItemText
            primary="Alternate Language Tagging Check"
            secondary="Verify alternate language tagging and hreflang tags are configured correctly"
          />
        </ListItemButton>
      </List>
    </Stack>
  );
}

export default SeoTechnical;
