import { Link } from "react-router-dom";
/* MUI */
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
/* MUI Icons */
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DataObjectIcon from '@mui/icons-material/DataObject';
import DifferenceIcon from '@mui/icons-material/Difference';
import LanguageIcon from '@mui/icons-material/Language';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';

function Home() {
  return (
    <Container maxWidth="lg" sx={{mb: 6}}>
      <Typography
        variant="h1"
        className="slant-header"
        sx={{pt: 3}}
      >
        Four Letter Data
      </Typography>
      <Typography variant="h2" sx={{mb: 1}}>
        Tools for web development and digital marketing
      </Typography>
      <Grid container spacing={{ xs: 1, md: 6}} sx={{mt: 0.5}}>
        <Grid item xs={12} md={4}>
          <Card sx={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}} variant="outlined">
            <Grid container sx={{p: 2}} spacing={3}>
              <Grid container item xs={12} md={3} justifyContent="center">
                <Avatar sx={(theme) => ({height: 60, width: 60, backgroundColor: theme.palette.primary.main})}>
                  <BrowserUpdatedIcon fontSize="large" />
                </Avatar>
              </Grid>
              <Grid item xs={12} md={9}>
                <Stack>
                  <Typography variant="h3" sx={{textAlign: {xs: 'center', md: 'left'}}}>
                    URL Performance
                  </Typography>
                  <Stack direction="row" spacing={0.25} sx={{flexWrap: 'wrap'}}>
                    <Chip label="Rendering" size="small" variant="outlined"/>
                    <Chip label="Performance" size="small" variant="outlined"/>
                    <Chip label="Keywords" size="small" variant="outlined"/>
                    <Chip label="API Integration" size="small" variant="outlined"/>
                    <Chip label="Scraping" size="small" variant="outlined"/>
                    <Chip label="QA" size="small" variant="outlined"/>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
            <CardContent>
              Fetch and render a URL to discover redirect issues, examine meta data, and verify schema and structured data
            </CardContent>
            <CardActions sx={{m: 1}}>
              <Button
                variant="contained"
                size="large"
                component={Link}
                to="/url-fetch"
                fullWidth
              >
                Fetch URL
              </Button>
            </CardActions>
            <Stack direction="row" sx={{mx: 2, mb: 1}} spacing={1}>
              <ScheduleIcon fontSize="small" color="lightGrey" />
              <Typography sx={(theme) => ({fontSize: 14, color: theme.palette.lightGrey.main})}>
                Schedule later with account
              </Typography>
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}} variant="outlined">
            <Grid container sx={{p: 2}} spacing={3}>
              <Grid container item xs={12} md={3} justifyContent="center">
                <Avatar sx={(theme) => ({height: 60, width: 60, backgroundColor: theme.palette.primary.main})}>
                  <CloudDownloadIcon fontSize="large" />
                </Avatar>
              </Grid>
              <Grid item xs={12} md={9}>
                <Stack>
                  <Typography variant="h3" sx={{textAlign: {xs: 'center', md: 'left'}}}>
                    Site Crawler
                  </Typography>
                  <Stack direction="row" spacing={0.25} sx={{flexWrap: 'wrap'}}>
                    <Chip label="Crawling" size="small" variant="outlined"/>
                    <Chip label="API Integration" size="small" variant="outlined"/>
                    <Chip label="CSV Export" size="small" variant="outlined"/>
                    <Chip label="Monitoring" size="small" variant="outlined"/>
                    <Chip label="QA" size="small" variant="outlined"/>
                    <Chip label="Scraping" size="small" variant="outlined"/>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
            <CardContent>
              Crawl a site and get URL status, meta data, and on-page content
            </CardContent>
            <CardActions sx={{m: 1}}>
              <Button
                variant="contained"
                size="large"
                component={Link}
                to="/crawl"
                fullWidth
              >
                Crawl a Site
              </Button>
            </CardActions>
            <Stack direction="row" sx={{mx: 2, mb: 1}} spacing={1}>
              <ScheduleIcon fontSize="small" color="lightGrey" />
              <Typography sx={(theme) => ({fontSize: 14, color: theme.palette.lightGrey.main})}>
                Schedule later with account
              </Typography>
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}} variant="outlined">
            <Grid container sx={{p: 2}} spacing={3}>
              <Grid container item xs={12} md={3} justifyContent="center">
                <Avatar sx={(theme) => ({height: 60, width: 60, backgroundColor: theme.palette.primary.main})}>
                  <DifferenceIcon fontSize="large" />
                </Avatar>
              </Grid>
              <Grid item xs={12} md={9}>
                <Stack>
                  <Typography variant="h3" sx={{textAlign: {xs: 'center', md: 'left'}}}>
                    Change Detection
                  </Typography>
                  <Stack direction="row" spacing={0.25} sx={{flexWrap: 'wrap'}}>
                    <Chip label="Crawling" size="small" variant="outlined"/>
                    <Chip label="Rendering" size="small" variant="outlined"/>
                    <Chip label="Keywords" size="small" variant="outlined"/>
                    <Chip label="Monitoring" size="small" variant="outlined"/>
                    <Chip label="Visual Changes" size="small" variant="outlined"/>
                    <Chip label="QA" size="small" variant="outlined"/>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
            <CardContent>
              Find and track changes to text, HTML, and rendering for websites and text
            </CardContent>
            <CardActions sx={{m: 1}}>
              <Button
                variant="contained"
                size="large"
                component={Link}
                to="/change"
                fullWidth
              >
                Find Changes
              </Button>
            </CardActions>
            <Stack direction="row" sx={{mx: 2, mb: 1}} spacing={1}>
              <ScheduleIcon fontSize="small" color="lightGrey" />
              <Typography sx={(theme) => ({fontSize: 14, color: theme.palette.lightGrey.main})}>
                Schedule later with account
              </Typography>
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}} variant="outlined">
            <Grid container sx={{p: 2}} spacing={3}>
              <Grid container item xs={12} md={3} justifyContent="center">
                <Avatar sx={(theme) => ({height: 60, width: 60, backgroundColor: theme.palette.primary.main})}>
                  <TextSnippetIcon fontSize="large" />
                </Avatar>
              </Grid>
              <Grid item xs={12} md={9}>
                <Stack>
                  <Typography variant="h3" sx={{textAlign: {xs: 'center', md: 'left'}}}>
                    Content Analysis
                  </Typography>
                  <Stack direction="row" spacing={0.25} sx={{flexWrap: 'wrap'}}>
                    <Chip label="Crawling" size="small" variant="outlined"/>
                    <Chip label="Keywords" size="small" variant="outlined"/>
                    <Chip label="CSV Export" size="small" variant="outlined"/>
                    <Chip label="NLP" size="small" variant="outlined"/>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
            <CardContent>
              Analyze unique and common keywords on different pages on a website
            </CardContent>
            <CardActions sx={{m: 1}}>
              <Button
                variant="contained"
                size="large"
                component={Link}
                to="/tfidf"
                fullWidth
              >
                Crawl and Analyze
              </Button>
            </CardActions>
            <Stack direction="row" sx={{mx: 2, mb: 1}} spacing={1}>
              <ScheduleIcon fontSize="small" color="lightGrey" />
              <Typography sx={(theme) => ({fontSize: 14, color: theme.palette.lightGrey.main})}>
                Schedule later with account
              </Typography>
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}} variant="outlined">
            <Grid container sx={{p: 2}} spacing={3}>
              <Grid container item xs={12} md={3} justifyContent="center">
                <Avatar sx={(theme) => ({height: 60, width: 60, backgroundColor: theme.palette.primary.main})}>
                  <LanguageIcon fontSize="large" />
                </Avatar>
              </Grid>
              <Grid item xs={12} md={9}>
                <Stack>
                  <Typography variant="h3" sx={{textAlign: {xs: 'center', md: 'left'}}}>
                    AltLang Check
                  </Typography>
                  <Stack direction="row" spacing={0.25} sx={{flexWrap: 'wrap'}}>
                    <Chip label="Crawling" size="small" variant="outlined"/>
                    <Chip label="CSV Export" size="small" variant="outlined"/>
                    <Chip label="International SEO" size="small" variant="outlined"/>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
            <CardContent>
              Check and verify inline alternate language tags
            </CardContent>
            <CardActions sx={{m: 1}}>
              <Button
                variant="contained"
                size="large"
                component={Link}
                to="/alt-lang"
                fullWidth
              >
                Check Tags
              </Button>
            </CardActions>
            <Stack direction="row" sx={{mx: 2, mb: 1}} spacing={1}>
              <ScheduleIcon fontSize="small" color="lightGrey" />
              <Typography sx={(theme) => ({fontSize: 14, color: theme.palette.lightGrey.main})}>
                Schedule later with account
              </Typography>
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}} variant="outlined">
            <Grid container sx={{p: 2}} spacing={3}>
              <Grid container item xs={12} md={3} justifyContent="center">
                <Avatar sx={(theme) => ({height: 60, width: 60, backgroundColor: theme.palette.primary.main})}>
                  <DataObjectIcon fontSize="large" />
                </Avatar>
              </Grid>
              <Grid item xs={12} md={9}>
                <Stack>
                  <Typography variant="h3" sx={{textAlign: {xs: 'center', md: 'left'}}}>
                    Find Schema
                  </Typography>
                  <Stack direction="row" spacing={0.25} sx={{flexWrap: 'wrap'}}>
                    <Chip label="Search" size="small" variant="outlined"/>
                    <Chip label="Keywords" size="small" variant="outlined"/>
                    <Chip label="Technical SEO" size="small" variant="outlined"/>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
            <CardContent>
              Search for pages that contain schema
            </CardContent>
            <CardActions sx={{m: 1}}>
              <Button
                variant="contained"
                size="large"
                component={Link}
                to="/schema-search"
                fullWidth
              >
                Search for Schema
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}} variant="outlined">
            <Grid container sx={{p: 2}} spacing={3}>
              <Grid container item xs={12} md={3} justifyContent="center">
                <Avatar sx={(theme) => ({height: 60, width: 60, backgroundColor: theme.palette.primary.main})}>
                  <ScreenSearchDesktopIcon fontSize="large" />
                </Avatar>
              </Grid>
              <Grid item xs={12} md={9}>
                <Stack>
                  <Typography variant="h3" sx={{textAlign: {xs: 'center', md: 'left'}}}>
                    iTunes Search
                  </Typography>
                  <Stack direction="row" spacing={0.25} sx={{flexWrap: 'wrap'}}>
                    <Chip label="Search" size="small" variant="outlined"/>
                    <Chip label="Competitive Analysis" size="small" variant="outlined"/>
                    <Chip label="App Store Optimization" size="small" variant="outlined"/>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
            <CardContent>
              Search the Apple App store and iTunes music store for apps, music, TV shows, and movies
            </CardContent>
            <CardActions sx={{m: 1}}>
              <Button
                variant="contained"
                size="large"
                component={Link}
                to="/itunes-search"
                fullWidth
              >
                Search iTunes
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={8}>
          <Card sx={(theme) => ({height: '100%', color: 'white', backgroundColor: theme.palette.primary.main})} elevation={6}>
            <CardHeader
              avatar={<ScheduleIcon fontSize="large" />}
              title={
                <Typography variant="h3">
                  Schedule for Later
                </Typography>
              }
            />
            <CardContent>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} md={8}>
                  <ul>
                    <li>Check pages daily, weekly, and monthly</li>
                    <li>Track visual, code, and content changes over time</li>
                    <li>Create custom data extractors to find and extract content from websites</li>
                    <li>Customizable API integration and scheduling for change detection, data warehousing, and site monitoring</li>
                    <li>Get alerts about site, technical, and content changes</li>
                  </ul>
                </Grid>
                <Grid item xs={12} md={4} sx={{textAlign: 'center'}}>
                  <Stack spacing={4}>
                    <Button
                      variant="contained"
                      size="large"
                      color="light"
                      component={Link}
                      to="/account/new"
                    >
                      Create Account
                    </Button>
                    <Button
                      variant="outlined"
                      color="light"
                      component={Link}
                      to="/account/login"
                    >
                      Login
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={12}>
          <Stack>
            <Stack direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="space-between"
              sx={(theme) => ({
                mt: 5,
                mx: .5,
                p: 1.5,
                color: 'white',
                backgroundColor: theme.palette.primary.main,
                transform: "skewx(-7.5deg)",
              })}
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <Avatar sx={(theme) => ({
                  height: 20,
                  width: 20,
                  backgroundColor: theme.palette.primary.main,
                  transform: "skewx(7.5deg)",
                })}>
                  <TipsAndUpdatesIcon fontSize="small" />
                </Avatar>
                <Typography variant="h3">
                  Tech Tips
                </Typography>
                <Chip sx={{transform: "skewx(7.5deg)"}} label="New" color="success" size="small" />
              </Stack>
            </Stack>
            <Card sx={(theme) => ({height: '100%', mr: 0.9})} variant="outlined">
              <CardContent>
                <Typography>
                  Learn how to use FLD Tools for digital marketing, web development, search engine optimization, and more.
                </Typography>
                <ul>
                  <li>How to measure and analyze website performance and page speed</li>
                  <li>Crawl websites to extract content, watch for updates, and process website data</li>
                  <li>Detect changes, updates, and errors on websites automatically</li>
                  <li>Analyze and monitor websites and pages for common SEO content, technical, and performance metrics</li>
                </ul>
                <Grid container>
                  <Grid item xs={12} md={9} />
                  <Grid item sx={12} md={3}>
                    <Button
                      variant="contained"
                      component={Link}
                      to="/tech-tips/getting-started"
                      fullWidth
                    >
                      Read More
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Home;
