import React from 'react';
import { Link } from "react-router-dom";
/* FLD */
import SiteSpeedGraph from '../SiteSpeedGraph/SiteSpeedGraph';
/* MUI */
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

function PerformanceRendering() {
  return (
    <Stack spacing={2} sx={{mb: 5}}>
      <Typography variant="h1" sx={{py: 1}}>
        Rendering a Page
      </Typography>
      <Typography variant="p">
        Page rendering issues and delays can easily drive up user wait times and lead to an inconsistent and jarring visual experience as your page is rendered and re-rendered when additional resources or features are loaded. Depending on how your site and web pages are configured a browser may have to wait for external resources to load before rendering can start so users have to stare at a blank browser screen until this happens.
      </Typography>
      <SiteSpeedGraph data={{
        connectStart: 100,
        connectEnd: 250,
        domainLookupStart: 200,
        domainLookupEnd: 200,
        redirectStart: 100,
        redirectEnd: 100,
        requestStart: 350,
        responseStart: 400,
        responseEnd: 500,
        domInteractive: 1500,
        domContentLoadedEventStart: 3000,
        domContentLoadedEventEnd: 4200,
        domComplete: 5500,
        loadEventEnd: 5500,
      }} />
      <Typography variant="p">
        Users might not be able to interact with your website until all resources have been loaded and fully rendered in the browser. Delays can prevent users from scrolling the webpage, seeing all on-page content and features, or interacting with dynamic elements.
      </Typography>
      <Typography variant="h3">
        Which Tools do I Use to Optimize Page Rendering?
      </Typography>
      <Typography variant="p">
        Page rendering optimization will likely require changes to how your front end is configured and designed as well as how features or resources are displayed on your webpage as it loads or as users interact with it. Reducing overall network requests, optimizing UX and styling, and reducing external resources that trigger re-renders can reduce overall rendering time and allow users to interact with your page sooner.
      </Typography>
      <List disablePadding>
        <ListItemButton
          component={Link}
          to={`/url-fetch`}
        >
          <ListItemAvatar>
            <Button
              size="small"
              variant="contained"
              sx={{mr: 2, width: 160}}
            >
              Start Now
            </Button>
          </ListItemAvatar>
          <ListItemText
            primary="URL Performance"
            secondary="The URL Performance tool shows how long each stage in the rendering process takes so you can optimize your front end to reduce delays. The tool also includes detailed performance metrics for external resources such as images, video, and code."
          />
        </ListItemButton>
      </List>
    </Stack>
  );
}

export default PerformanceRendering;
